<template>
  <div class="list-info">
    <top-bar :title="'三方协同'" :left="true"></top-bar>
    <div class="search">
      <input v-model="dataForm.searchValue" type="text" placeholder="请输入具体任务名称" class="search-content">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="dateBarShow=!dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666' }">预计完成时间</p>
        </van-col>
        <van-col span="8">
          <p @click="subareaShow=!subareaShow" :style="{color:subareaShow?'#387FF5':'#666666'}" >
            {{ show.subarea }}
            <img :src="require(`@/assets/img/${subareaShow?'pull-down-select':'pull-down'}.png`)" alt=""
                 class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="taskTypeShow=!taskTypeShow" :style="{color:taskTypeShow?'#387FF5':'#666666'}">
            {{ show.taskType }}
            <img :src="require(`@/assets/img/${taskTypeShow?'pull-down-select':'pull-down'}.png`)" alt=""
                 class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow">
          <span>{{ dataForm.beginDate == '' ? '最早' : dataForm.beginDate }}</span></van-col>
        <van-col span="2"><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow">
          <span>{{ dataForm.endDate == '' ? '至今' : dataForm.endDate }}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="beginDateShow" position="bottom">
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" type="date"
                           :formatter="formatDate" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom">
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="date" :formatter="formatDate"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date(new Date().getFullYear()+5, 10, 1)"/>
    </van-popup>
    <van-popup v-model="subareaShow" position="bottom">
      <van-picker title="请选择" show-toolbar :columns="subareaList" value-key="label" @confirm="subareaConfirm"
                  cancel-button-text="重置"
                  @cancel="subareaCancel"/>
    </van-popup>
    <van-popup v-model="taskTypeShow" position="bottom">
      <van-picker title="请选择" show-toolbar :columns="taskTypeList" value-key="label" @confirm="taskTypeConfirm"
                  cancel-button-text="重置"
                  @cancel="taskTypeCancel"/>
    </van-popup>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <!-- <van-pull-refresh v-model="pullLoading" @refresh="onPullDownRefresh"> -->
      <div class="cont">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                  v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{ totalCount }}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{ item.subareaStr }}
              </div>
              <div class="title-right"
                   :style="{color: item.judgeStatus=='已完成'? '#3E7EFE':item.judgeStatus=='已超期'?'red':'#F18614'}">
                {{ item.judgeStatus }}
              </div>
            </div>
            <van-row style="width: 100%"  class="rowContent">
              <van-col :span="12">
                <div class="top-text divContent">{{ item.projectTask }}</div>
                <div class="top-text divContent">{{ item.concretenessTask }}</div>
              </van-col>
              <van-col :span="12">
                <div style="text-align: right" class="divContent">{{ item.accomplishTime }}</div>
                <div style="text-align: right" class="divContent">
                  <van-icon name="arrow" color="#666" style="text-align: right"/>
                </div>
              </van-col>
            </van-row>
          </div>
        </van-list>
        <van-empty description="没有数据哦" v-if="dataList.length < 1"/>
      </div>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate,newDate} from '@/utils/utils'
import {getbelongSubArea} from '@/utils/common'
import {mapMutations} from 'vuex'

export default {
  components: {
    topBar
  },
  data() {
    return {
      loading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      subareaShow: false,
      taskTypeShow: false,
      pullLoading: false,
      page: 0,
      limit: 10,
      searchValue: '',
      dataForm: {
        endDate: '',
        beginDate: '',
        subarea: '',
        taskType: '',
        workbenchAlert:""
      },
      show: {
        subarea: '所属小区',
        taskType: '完成状态'
      },
      totalCount: 0,
      taskTypeList: [{label:'已完成',value:'1'},{label:'已超期',value:'2'},{label:'进行中',value:'3'}],
      subareaList: [],
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    getInfo(id) {
      this.$router.push({path: '/tripleCotasking-add', query: {id: id}})
    },
    getDataList() {
      this.page++
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/apptriplecotasking/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          searchValue: this.dataForm.searchValue,
          startTime: this.dataForm.beginDate,
          endTime: this.dataForm.endDate,
          subarea:this.dataForm.subarea,
          taskType: this.dataForm.taskType,
          orgId:this.dataForm.subarea||this.$orgId,
          workbenchAlert:this.dataForm.workbenchAlert
        })
      }).then(({data}) => {
        // debugger
        this.pullLoading = false
        if (data.code == 0) {
          this.totalCount = data.page.totalCount

          data.page.list.map(e=>{
            e["judgeStatus"]="已完成"
            e.childList.map(c=>{
              if(c.alert==1){
                e.judgeStatus="已超期"
                return
              }else if(c.accomplish==0){
                e.judgeStatus="进行中"
                return
              }
            })
          })

          this.dataList = this.dataList.concat(data.page.list)
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    onSearch() {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.dataForm.searchValue = ''
      this.getDataList()
    },
    beginDateConfim(value) {
      let d = new Date(this.dataForm.endDate)
      if (this.dataForm.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.dataForm.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim(value) {
      let d = newDate(this.dataForm.beginDate)
      if (this.dataForm.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.dataForm.endDate = formatterDate(value)
      this.endDateShow = false
    },
    changeDate() {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    dateClose() {
      this.dataForm.beginDate= ''
      this.dataForm.endDate=''

    },
    subareaConfirm(value) {
      this.page = 0
      this.dataList = []
      this.dataForm.subarea = value.value
      this.show.subarea = value.label
      this.getDataList()
      this.subareaShow = false
    },
    subareaCancel(value){

      this.page = 0
      this.dataList = []
      this.dataForm.subarea = ""
      this.show.subarea = "所属小区"
      this.getDataList()
      this.subareaShow = false
    },
    taskTypeConfirm(value) {
      this.page = 0
      this.dataList = []
      this.dataForm.taskType = value.value
      this.show.taskType = value.label
      this.getDataList()
      this.taskTypeShow = false
    },
    taskTypeCancel(value){
      this.page = 0
      this.dataList = []
      this.dataForm.taskType = ""
      this.show.taskType = "完成状态"
      this.getDataList()
      this.taskTypeShow = false
    },
    /**
     * 页面相关事件处理函数--用户下拉动作
     */
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    goAdd() {
      this.$router.push('/tripleCotasking-add')
    }
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });

    if(this.$route.query.workbenchAlert=='1'){
        this.dataForm.workbenchAlert=1
    }

    this.userId = this.$globalData.userInfo.userId
    let that =this
    getbelongSubArea(this.$orgId, function (e) {
      that.subareaList = e
    });
    this.getDataList()
  }
}
</script>
<style scoped>
.list-item{
  /*padding: 0.3rem 0.4rem !important;*/
}
.rowContent{
  padding-bottom: 20px;
}
.divContent{
  padding-top: 15px;
}
</style>
